@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  background-color: #f6f4ef;
  min-height: 100vh;
  font-family: "BV Celine";
  font-weight: normal;
  font-style: normal;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.main {
  width: 80%;
}

.sidebar {
  width: 20%;
  height: 100vh;
}

@layer components {
  .boobs {
    margin-top: -95vh;
  }
}

@font-face {
  font-family: 'BV Celine';
  src: local('BVCelineRegular'), url(./fonts/BVCelineRegular.woff) format('woff');
}